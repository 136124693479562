<template>
  <div v-if="isTableShow">
    <div class="vx-row mb-12"></div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <!-- <th width="15%">
              <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
            </th> -->
          </template>
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.payment">
              <vs-td>
                <vx-tooltip text="Details" class="mr-4">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    v-on:click.stop="handleShow(tr)"
                  /> </vx-tooltip
              ></vs-td>
              <vs-td> {{ tr.code }}</vs-td>
              <vs-td>
                {{ tr.gi_number }}
              </vs-td>
              <vs-td> {{ tr.shipment_number }}</vs-td>
              <vs-td>
                Code : {{ tr.customer_code }}
                <br />
                Name : {{ tr.customer_name }}
                <br />
                Address : {{ tr.customer_address }}
                <br />
              </vs-td>
              <vs-td>
                Code : {{ tr.so_code }}
                <br />
                Posting Date :
                {{ tr.so_posting_date ? tr.so_posting_date.split("T")[0] : "" }}
                <br />
                Invoice Code : {{ tr.so_invoice_code }}
              </vs-td>
              <vs-td>
                Code : {{ tr.do_code }}
                <br />
                Posting Date :
                {{ tr.do_posting_date ? tr.do_posting_date.split("T")[0] : "" }}
              </vs-td>
              <vs-td>
                <ul v-if="tr.items.length">
                  <li v-for="item in tr.items.split('|')" :key="item">
                    {{ item }}
                    <br />
                  </li>
                </ul>
                <span v-else>{{ tr.items }}</span>
              </vs-td>
              <vs-td>
                {{ capitalize(tr.notes) }}
              </vs-td>
              <vs-td>
                {{ generatePODStatus(tr.status_delivery) }}
              </vs-td>
              <vs-td>
                <vs-checkbox disabled v-model="tr.is_document"></vs-checkbox>
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div class="flex flex-col">
            <div
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </div>
            <pod_detail :podData="selectedData" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import formApply from "./apply-form.vue";
import moment from "moment";
import pod_detail from "./pod_detail.vue";
export default {
  props: {
    depositDate: Object,
    warehouseID: Number,
    shipmentNumbers: Array,
    deliveryOrderNumbers: Array,
    optionShipmentNumber: Array,
    optionDeliveryOrderNumber: Array,
    filterSearch: Number,
  },
  components: {
    "form-apply": formApply,
    pod_detail,
  },
  data() {
    return {
      typePayment: ["", "Trade", "Deposit", "Open Deposit", "Credit Note"],
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "shipment_number",
      },
      header: [
        {
          text: "Action",
        },
        {
          text: "Code",
          value: "customer_code",
          // width: '5%'
        },
        {
          text: "GI Number",
        },
        {
          text: "Shipment Number",
        },
        {
          text: "Customer Data",
          value: "reference_code",
        },
        {
          text: "SO Data",
          sortable: false,
        },
        {
          text: "DO/DN Data",
          value: "created_at",
        },
        {
          text: "List Item",
          value: "created_at",
        },
        {
          text: "Source",
          value: "created_at",
        },
        {
          text: "POD Status",
          value: "created_at",
        },
        {
          text: "Document",
          value: "created_at",
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      checkedAll: false,
      checked: [],
      dataChecked: [],
      isTableShow: false,
    };
  },
  computed: {},
  watch: {
    filterSearch() {
      console.log("this.filterSearch: ", this.filterSearch);
      if (this.filterSearch > 0) {
        this.reloadData(this.params);
        if (!this.isTableShow) {
          this.isTableShow = true;
        }
      }
      console.log("running search");
    },
  },
  mounted() {
    // this.reloadData(this.params);
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    applyPayment(tr) {
      this.detail = true;
      this.selectedData = tr;
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    addChecked(val, index) {
      console.log(this.dataChecked, "check");
      console.log(this.checked.length, "leng");
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
        this.dataChecked = this.dataChecked.filter((v) => {
          return v.ID != val;
        });
      } else {
        this.checked.push(val);
        this.dataChecked.push(this.responseData.payment[index]);
      }
    },
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.detail = true;
    },
    addAllChecked() {
      console.log(this.responseData.payment);
      if (this.checkedAll == false) {
        for (var i = 0; i < this.responseData.payment.length; i++) {
          if (this.checked.includes(this.responseData.payment[i].ID)) {
            //
          } else {
            this.checked.push(this.responseData.payment[i].ID);
            this.dataChecked.push(this.responseData.payment[i]);
          }
        }
      } else {
        for (var j = 0; j < this.responseData.payment.length; j++) {
          this.checked.splice(
            this.checked.indexOf(this.responseData.payment[j].ID),
            1
          );
          this.dataChecked = this.dataChecked.filter((v) => {
            return v.ID != this.responseData.payment[j].ID;
          });
        }
      }
    },
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    generatePODStatus(statusNumber) {
      switch (statusNumber) {
        case 0:
          return "Open";
        case 1:
          return "Complete";
        case 2:
          return "Partial Complete";
        case 3:
          return "Partial Redeliver";
        case 4:
          return "Failed Complete";
        case 5:
          return "Failed Redeliver";
      }
    },
    check(val, index) {
      if (this.checked.includes(val)) {
        console.log(this.checked);
        this.checked.splice(this.checked.indexOf(val), 1);
        this.dataChecked = this.dataChecked.filter((v) => {
          return v.dn_id != val;
        });
      } else {
        this.checked.push(val);
        console.log(this.checked);
        // this.table.data[index].paid_now = this.table.data[index].dn_value - this.table.data[index].used_dn_value
        this.dataChecked.push(this.table.data[index]);
      }
    },
    datatable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/proof-of-delivery", {
            params: {
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.order,
              sort: params.sort,
              type: 1,
              // shipment_number: this.shipmentNumber ? this.shipmentNumber : null,
              // warehouse_id: this.warehouseID ? this.warehouseID : undefined,
              warehouse_ids: this.warehouseID ? [this.warehouseID] : undefined,
              start_delivery_date: this.depositDate.startDate
                ? moment(this.depositDate.startDate).format("YYYY-MM-DD")
                : null,
              end_delivery_date: this.depositDate.endDate
                ? moment(this.depositDate.endDate).format("YYYY-MM-DD")
                : null,
              territory_ids: params.territory_ids,
              shipment_numbers: params.shipment_numbers,
              delivery_order_numbers: params.delivery_order_numbers,
              // status: params.status ? params.status : 0,
              // type: params.type ? params.type : 0,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    reloadData(params) {
      let shipment_numbers = null;
      if (
        this.shipmentNumbers.length === 1 &&
        this.shipmentNumbers[0].shipment_number === "All"
      ) {
        const temp = [...this.optionShipmentNumber];
        temp.shift();
        shipment_numbers = temp.map((item) => item.shipment_number);
      } else {
        shipment_numbers = this.shipmentNumbers.map(
          (item) => item.shipment_number
        );
      }

      let delivery_order_numbers = null;
      if (
        this.deliveryOrderNumbers.length === 1 &&
        this.deliveryOrderNumbers[0].do_number === "All"
      ) {
        const temp = [...this.optionDeliveryOrderNumber];
        temp.shift();
        delivery_order_numbers = temp.map((item) => item.do_number);
      } else {
        delivery_order_numbers = this.deliveryOrderNumbers.map(
          (item) => item.do_number
        );
      }
      this.params = {
        ...params,
        shipment_numbers,
        delivery_order_numbers,
        warehouse_id: this.warehouseID,
        territory_ids:
          this.$store.getters["operatingUnit/getSelectedTerritoryId"],
      };
      console.log(this.params);
      this.$vs.loading();

      const dataTable = this.datatable(this.params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          // this.data = r.data.payment;
          this.responseData = r.data;
          console.log("r.data", r.data);
          // this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    processPod() {
      if (this.checked.length < 1) {
        this.$vs.notify({
          title: "Failed",
          text: "Please choose one POD",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return false;
      }

      const params = {
        pod_id: this.checked,
      };

      this.$vs.loading();
      this.$http
        .post("/api/v1/proof-of-delivery/process", params)
        .then((result) => {
          this.$vs.loading.close();
          //   // if(r.stat)
          if (result.code == 500) {
            this.$vs.notify({
              title: "Failed",
              text: result.message,
              iconPack: "feather",
              position: "top-right",
              icon: "icon-alert-circle",
              color: "warning",
            });
            this.$vs.loading.close();
            // this.reloadData(this.params);
          } else if (result.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: result.message,
              iconPack: "feather",
              position: "top-right",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.$vs.loading.close();
            // this.reloadData(this.params);
          }
        });
      this.reloadData(this.params);
    },
    capitalize(str) {
      if (str.includes("ePod") || str.includes("Pod")) {
        return str;
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 46%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
