var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isTableShow)?_c('div',[_c('div',{staticClass:"vx-row mb-12"}),_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.detail ? _vm.detailShow + ' vertical-divider' : '',
        _vm.detailHide,
      ]},[_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"thead"}),_c('template',{slot:"tbody"},_vm._l((_vm.responseData.payment),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',[_c('vx-tooltip',{staticClass:"mr-4",attrs:{"text":"Details"}},[_c('vs-button',{attrs:{"type":"line","icon-pack":"feather","icon":"icon-eye"},on:{"click":function($event){$event.stopPropagation();return _vm.handleShow(tr)}}})],1)],1),_c('vs-td',[_vm._v(" "+_vm._s(tr.code))]),_c('vs-td',[_vm._v(" "+_vm._s(tr.gi_number)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.shipment_number))]),_c('vs-td',[_vm._v(" Code : "+_vm._s(tr.customer_code)+" "),_c('br'),_vm._v(" Name : "+_vm._s(tr.customer_name)+" "),_c('br'),_vm._v(" Address : "+_vm._s(tr.customer_address)+" "),_c('br')]),_c('vs-td',[_vm._v(" Code : "+_vm._s(tr.so_code)+" "),_c('br'),_vm._v(" Posting Date : "+_vm._s(tr.so_posting_date ? tr.so_posting_date.split("T")[0] : "")+" "),_c('br'),_vm._v(" Invoice Code : "+_vm._s(tr.so_invoice_code)+" ")]),_c('vs-td',[_vm._v(" Code : "+_vm._s(tr.do_code)+" "),_c('br'),_vm._v(" Posting Date : "+_vm._s(tr.do_posting_date ? tr.do_posting_date.split("T")[0] : "")+" ")]),_c('vs-td',[(tr.items.length)?_c('ul',_vm._l((tr.items.split('|')),function(item){return _c('li',{key:item},[_vm._v(" "+_vm._s(item)+" "),_c('br')])}),0):_c('span',[_vm._v(_vm._s(tr.items))])]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.capitalize(tr.notes))+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.generatePODStatus(tr.status_delivery))+" ")]),_c('vs-td',[_c('vs-checkbox',{attrs:{"disabled":""},model:{value:(tr.is_document),callback:function ($$v) {_vm.$set(tr, "is_document", $$v)},expression:"tr.is_document"}})],1)],1)}),1)],2)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
          _vm.detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
          _vm.detailHide,
        ]},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.closeDetail}},[_vm._v("Close")])],1),_c('pod_detail',{attrs:{"podData":_vm.selectedData}})],1)]):_vm._e()])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }