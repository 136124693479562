<template>
  <div>
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="payment.CustomerCode" />
      </div>
    </div>
    <br />
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer Name</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="payment.CustomerName" />
      </div>
    </div>
    <br />
    <div class="vx-row margin-btm" style="width: 100%">
      <vs-alert
        v-if="totalSelected <= payment.Amount"
        color="success"
        class="mt-3"
        icon-pack="feather"
        close-icon="icon-x"
      >
        Total Amount : {{ priceFormat(payment.Amount) }}
        <hr />
        Total Amount Selected : {{ priceFormat(totalSelected) }}
        <hr />
        Remaining : {{ priceFormat(remaining) }}
      </vs-alert>
      <vs-alert
        v-else
        color="warning"
        class="mt-3"
        icon-pack="feather"
        close-icon="icon-x"
      >
        Total Amount : {{ priceFormat(payment.Amount) }}
        <hr />
        Total Amount Selected : {{ priceFormat(totalSelected) }}
        <hr />
        Remaining : {{ priceFormat(remaining) }}
      </vs-alert>
    </div>
    <div style="width: 100%">
      <vs-table bordered stripe :sst="true" :data="dataPayment">
        <template slot="thead">
          <vs-th style="width: 5%">
            <vs-checkbox v-on:click="addAllChecked()" v-model="checkedAll">
              Check All
            </vs-checkbox>
          </vs-th>
          <vs-th>Invoice</vs-th>
          <!-- <vs-th>Date</vs-th> -->
          <vs-th>Amount</vs-th>
          <vs-th style="width: 15%">Unpaid</vs-th>
          <vs-th style="width: 15%">Paid Now</vs-th>
          <vs-th>Note</vs-th>
          <!-- <vs-th>Nro</vs-th> -->
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td
              ><vs-checkbox
                :checked="checked.includes(tr.ID)"
                v-on:click="addChecked(tr.ID, indextr)"
              ></vs-checkbox
            ></vs-td>
            <vs-td>Code : {{ tr.Code }}
              <br>
              Due Date : {{ dateFormat(tr.DueDate) }}
            </vs-td>
            <!-- <vs-td>{{ dateFormat(tr.Date) }}</vs-td> -->
            <vs-td style="text-align: right">
              <!-- Amount : -->
              {{ priceFormat(tr.Total) }}
              <!-- <br /> -->
              <!-- Paid :{{ priceFormat(tr.Paid) }} -->
            </vs-td>
            <vs-td style="text-align: right">
              {{ priceFormat(tr.Unpaid) }}
            </vs-td>
            <vs-td style="text-align: right">
              <template v-if="checked[checked.length - 1] == tr.ID">
                <vs-input
                  class="w-full"
                  v-model="dataChecked[checked.length - 1].PaidNow"
                  @keyup="
                    dataChecked[checked.length - 1].PaidNow = formatPrice(
                      dataChecked[checked.length - 1].PaidNow.toString()
                    )
                  "
                />
              </template>
              <template v-else-if="checked.includes(tr.ID)">
                {{ priceFormat(tr.PaidNow) }}
              </template>
              <template v-else> 0 </template>
            </vs-td>
            <vs-td>
              <vs-textarea v-model="tr.Note" maxlength ="255" class="w-full"></vs-textarea>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <br />
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>

      <vs-button @click="handleSubmit()" icon-pack="feather" icon="icon-save">
        Submit
      </vs-button>
    </div>
  </div>
</template>
<script>
import {
  dataInvoiceCustomer,
  postPaymentLine,
} from "../../../../services/api/payment";
import moment from "moment";
export default {
  props: {
    payment: Object,
  },
  data() {
    return {
      dataPayment: [],
      checkedAll: false,
      checked: [],
      dataChecked: [],
    };
  },
  computed: {
    totalSelected: function () {
      console.log('compute')
      return this.dataChecked.reduce(
        (acc, value) =>
          acc +
          parseInt(
            value.PaidNow.toString()
              .replace(/[^,\d]/g, "")
              .toString()
          ),
        0
      );
    },
    remaining: function () {
      return this.payment.Amount - this.totalSelected;
    },
  },
  mounted() {
    console.log(this.payment);
    this.loadInvoice();
  },
  methods: {
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    addChecked(val, index) {
      console.log(this.dataChecked, "check");
      console.log(this.checked.length, "leng");
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
        this.dataChecked = this.dataChecked.filter((v) => {
          return v.ID != val;
        });
      } else {
        this.checked.push(val);
        this.dataChecked.push(this.dataPayment[index]);
      }
    },
    handleSubmit() {
      console.log(this.dataChecked);
      if (this.remaining < 0) {
        this.$vs.notify({
          title: "Failed",
          text: "Remaining must greater than 0!",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return false;
      }
      if (this.dataChecked.length < 1) {
        this.$vs.notify({
          title: "Failed",
          text: "Please choose one invoice",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return false;
      }

      const checkPaidZero = this.dataChecked.filter((row) => {
        return row.PaidNow == 0;
      });
      if (checkPaidZero.length > 0) {
        this.$vs.notify({
          title: "Failed",
          text: "Selected paid now must greater than 0!",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return false;
      }
      this.$vs.loading();
      this.dataChecked.map((row, index) => {
        this.dataChecked[index].PaidNow = parseInt(
          this.dataChecked[index].PaidNow.toString()
            .replace(/[^,\d]/g, "")
            .toString()
        );
      });
      const params = {
        payment_id: this.payment.ID,
        payment_line: this.dataChecked,
      };
      const postPayment = postPaymentLine(params);
      postPayment.then((r) => {
        // if(r.stat)
        if (r.code == 500) {
          this.$vs.notify({
            title: "Failed",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "warning",
          });
        } else if (r.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "success",
          });
        }
        this.$nextTick(() => {
          // this.$emit("closeDetail");
          this.$emit("closeDetail", true);
          this.$vs.loading.close();
        });
      });
    },
    formatPrice(angka, prefix = "") {
      console.log(angka);
      var number_string = angka.replace(/[^,\d]/g, "").toString();
      var split = number_string.split(",");
      split[0] = parseInt(split[0]).toString();
      var sisa = split[0].length % 3;
      var rupiah = split[0].substr(0, sisa);
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      var separator = "";
      if (ribuan) {
        if (sisa) {
          separator = ".";
        } else {
          separator = "";
        }
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
    },
    addAllChecked() {
      if (this.checkedAll == false) {
        for (var i = 0; i < this.dataPayment.length; i++) {
          if (this.checked.includes(this.dataPayment[i].ID)) {
            //
          } else {
            this.checked.push(this.dataPayment[i].ID);
            this.dataChecked.push(this.dataPayment[i]);
          }
        }
      } else {
        for (var j = 0; j < this.dataPayment.length; j++) {
          this.checked.splice(this.checked.indexOf(this.dataPayment[j].ID), 1);
          this.dataChecked = this.dataChecked.filter((v) => {
            return v.ID != this.dataPayment[j].ID;
          });
        }
      }
    },
    loadInvoice() {
      const invoiceCustomer = dataInvoiceCustomer(this.payment.CustomerCode);
      invoiceCustomer.then((r) => {
        this.dataPayment = [];
        r.data.invoice.map((row) => {
          this.dataPayment.push({ ...row, ...{ PaidNow: row.Unpaid, Note: '' } });
        });
        console.log(this.dataPayment);
      });
    },
  },
};
</script>