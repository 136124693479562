<template>
  <vx-card title="POD Processing">
    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <multiselect
            v-model="territory.selected"
            :options="territory.options"
            placeholder="Type to search"
            track-by="name"
            label="name"
            :max-height="125"
            @input="territoryChange()"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} - {{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>

          <vs-button
            class="ml-4"
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            @click="
              () => {
                this.territory.selected = null;
              }
            "
          ></vs-button>
        </div>
      </div>
    </div> -->
    <div class="mb-6">
      <territory-filter />
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Warehouse</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <multiselect
            class="selectExample"
            v-model="filter_warehouse"
            :options="
              !optionFilterWarehouse || optionFilterWarehouse.length == 0
                ? []
                : optionFilterWarehouse
            "
            :multiple="false"
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            :limit="4"
            placeholder="Type to search"
            track-by="id"
            label="warehouse_name"
            :disabled="false"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.warehouse_code }} -
                  {{ props.option.warehouse_name }}</span
                >
              </div>
            </template>
          </multiselect>

          <vs-button
            class="ml-4"
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            @click="
              () => {
                this.filter_warehouse = null;
                this.filterWarehouseID = 0;
              }
            "
          ></vs-button>
        </div>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Delivery Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="depositDate"
            :linkedCalendars="true"
            :name="depositDate"
          >
            <template v-slot:input="picker" style="min-width: 350px">
              {{ globalDateFormat(picker.startDate) }} -
              {{ globalDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
          <vs-button
            class="ml-4"
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            @click="
              () => {
                this.depositDate = { startDate: null, endDate: null };
              }
            "
          ></vs-button>
          <!-- <template>
            <vx-tooltip text="reset">
              <vs-button
                type="line"
                icon-pack="feather"
                icon="icon-refresh-cw"
                @click.stop="handleResetInvoiceDateFilter()"
              />
            </vx-tooltip>
          </template> -->
        </div>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Shipment Number</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <multiselect
            v-model="selectedShipmentNumbers"
            :options="
              !optionShipmentNumber || optionShipmentNumber.length == 0
                ? []
                : optionShipmentNumber
            "
            :multiple="true"
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            :limit="2"
            placeholder="Type shipment number"
            track-by="transport_plan_id"
            label="shipment_number"
            :custom-label="queryShipmentNumber"
            @search-change="onShipmentFilterChange"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">
                  {{ props.option.shipment_number }}</span
                >
              </div>
            </template>
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span>{{ props.option.shipment_number }}</span>
                <i
                  class="multiselect__tag-icon"
                  @click.prevent
                  @mousedown.prevent.stop="props.remove(props.option, $event)"
                />
              </div>
            </template>
          </multiselect>
          <!-- <vs-input
            class="w-full"
            name="Shipment Number"
            v-model="shipment_number"
            placeholder="Shipment Number"
          />
          <vs-button
            class="ml-4"
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            @click="
              () => {
                this.depositDate = { startDate: null, endDate: null };
              }
            "
          ></vs-button> -->
          <!-- <template>
            <vx-tooltip text="reset">
              <vs-button
                type="line"
                icon-pack="feather"
                icon="icon-refresh-cw"
                @click.stop="handleResetInvoiceDateFilter()"
              />
            </vx-tooltip>
          </template> -->
        </div>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Delivery Order</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <multiselect
            v-model="selectedDeliveryOrders"
            :options="
              !optionDeliveryOrder || optionDeliveryOrder.length == 0
                ? []
                : optionDeliveryOrder
            "
            :multiple="true"
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            :limit="3"
            placeholder="Type delivery number"
            track-by="do_id"
            label="shipment_number"
            :custom-label="queryDeliveryNumber"
            @search-change="onDeliveryOrderChange"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.do_number }}</span>
              </div>
            </template>
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span>{{ props.option.do_number }}</span>
                <i
                  class="multiselect__tag-icon"
                  @click.prevent
                  @mousedown.prevent.stop="props.remove(props.option, $event)"
                />
              </div>
            </template>
          </multiselect>
        </div>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-7/12 w-full"></div>
      <div class="vx-col sm:w-5/12 w-full">
        <vs-button class="mb-2" @click="getData">Get Data</vs-button>
      </div>
    </div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + 'md:w-1/4 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-tabs :color="colorx" v-model="currentTab">
          <vs-tab @click="colorx = 'danger'" label="Completed">
            <div class="con-tab-ejemplo">
              <apply
                :depositDate="depositDate"
                :warehouseID="
                  filter_warehouse ? filter_warehouse.warehouse_id : 0
                "
                :shipmentNumbers="selectedShipmentNumbers"
                :deliveryOrderNumbers="selectedDeliveryOrders"
                :optionDeliveryOrderNumber="optionDeliveryOrder"
                :optionShipmentNumber="optionShipmentNumber"
                :filterSearch="buttonSearch"
              />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'warning'" label="Processed">
            <div class="con-tab-ejemplo">
              <open
                :depositDate="depositDate"
                :warehouseID="
                  filter_warehouse ? filter_warehouse.warehouse_id : 0
                "
                :shipmentNumbers="selectedShipmentNumbers"
                :deliveryOrderNumbers="selectedDeliveryOrders"
                :optionDeliveryOrderNumber="optionDeliveryOrder"
                :optionShipmentNumber="optionShipmentNumber"
                :filterSearch="buttonSearch"
              />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-3/4 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- form component -->
            <create @close="handleClose" />
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import open from "./open.vue";
import apply from "./apply.vue";
import reconcile from "./reconcile.vue";
import done from "./done.vue";
import reversed from "./reversed.vue";
import waitingReverse from "./waiting_reverse.vue";
import territoryFilter from "@/components/operatingUnitFilter/territoryFilter.vue";
import moment from "moment";

export default {
  components: {
    open,
    apply,
    reconcile,
    done,
    reversed,
    "waiting-reverse": waitingReverse,
    // create,
    // execution,
    // cancel,
    // mapping,
    // recon,
    // waiting,
    // failed,
    // byCustomer,
    DateRangePicker,
    "territory-filter": territoryFilter,
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      colorx: "red",
      dn_id: "",
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      componentKey: 0,
      postingDate: {},
      filterWarehouseID: 0,
      filter_warehouse: null,
      buttonSearch: 0,
      territory: {
        selected: null,
        options: [],
      },
      depositDate: {
        startDate: new Date(),
        endDate: new Date(),
      },
      optionFilterWarehouse: [],
      optionFilterTerritory: [],
      optionShipmentNumber: [],
      optionDeliveryOrder: [],
      selectedShipmentNumbers: [],
      selectedDeliveryOrders: [],
      optionAllShipmentNumber: {
        shipment_number: "All",
        id: 0,
      },
      optionAllDeliveryOrder: {
        do_number: "All",
        do_id: 0,
      },
      currentTab: 0,
    };
  },
  mounted() {
    this.selectedShipmentNumbers.push(this.optionAllShipmentNumber);
    this.selectedDeliveryOrders.push(this.optionAllDeliveryOrder);
    this.getOptionWarehouse();
    this.getOptionShipmentNumber(this.currentTab);
    // this.getOptionTerritory();
  },
  created() {
    this.$store.watch(
      (state) => state.operatingUnit.filter.selectedTerritories,
      (newVal) => {
        // this.optionFilterWarehouse = [];
        // if (newVal.length === 0) {
        //   return;
        // } else if (newVal.length > 1 && newVal[0].TerritoryName === "All") {
        //   return;
        // }
        this.filter_warehouse = null;
        this.selectedDeliveryOrders = [this.optionAllDeliveryOrder];
        this.selectedShipmentNumbers = [this.optionAllShipmentNumber];
        this.getOptionWarehouse();
        this.getOptionShipmentNumber(this.currentTab);
      }
    );
  },
  watch: {
    selectedShipmentNumbers() {
      this.selectedDeliveryOrders = [this.optionAllDeliveryOrder];
      if (this.selectedShipmentNumbers.length === 0) {
        this.selectedShipmentNumbers.push(this.optionAllShipmentNumber);
        return;
      } else if (
        this.selectedShipmentNumbers.length > 1 &&
        this.selectedShipmentNumbers[0].shipment_number === "All"
      ) {
        this.selectedShipmentNumbers.shift();
        return;
      }

      if (this.selectedShipmentNumbers.length === 0) {
        return;
      }

      this.getOptionDeliveryOrder(this.currentTab);
    },
    selectedDeliveryOrders() {
      if (this.selectedDeliveryOrders.length === 0) {
        this.selectedDeliveryOrders.push(this.optionAllDeliveryOrder);
      } else if (
        this.selectedDeliveryOrders.length > 1 &&
        this.selectedDeliveryOrders[0].do_number === "All"
      ) {
        this.selectedDeliveryOrders.shift();
      } else {
        return;
      }
    },
    depositDate() {
      this.selectedDeliveryOrders = [this.optionAllDeliveryOrder];
      this.selectedShipmentNumbers = [this.optionAllShipmentNumber];
      this.getOptionShipmentNumber(this.currentTab);
    },
    filter_warehouse() {
      this.selectedDeliveryOrders = [this.optionAllDeliveryOrder];
      this.selectedShipmentNumbers = [this.optionAllShipmentNumber];
      this.getOptionShipmentNumber(this.currentTab);
    },
    currentTab() {
      this.selectedDeliveryOrders = [this.optionAllDeliveryOrder];
      this.selectedShipmentNumbers = [this.optionAllShipmentNumber];
      this.getOptionShipmentNumber(this.currentTab);
    },
  },
  methods: {
    dateFormat(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    handleClose() {
      this.detail = false;
      this.componentKey += 1;
      this.$refs.open.closeDetail();
    },
    handleOpenForm() {
      this.detail = true;
      this.status_detail = "form-create";
    },
    getData() {
      this.buttonSearch += 1;
    },
    // territoryChange: function () {
    //   console.log(this.territory.selected);
    //   this.getOptionWarehouse();

    //   this.filterWarehouseID = this.filter_warehouse.warehouse_id;
    // },
    getOptionWarehouse() {
      this.$vs.loading();

      this.$http
        .get("/api/v1/master/warehouse-territory/options", {
          params: {
            order: "warehouse_name",
            sort: "asc",
            territory_ids:
              this.$store.getters["operatingUnit/getSelectedTerritoryId"],
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionFilterWarehouse = resp.data;
            if (this.optionFilterWarehouse.length === 1) {
              this.filter_warehouse = resp.data[0];
              this.filterWarehouseID = resp.data[0].warehouse_id;
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Warehouse option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: err.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          this.$vs.loading.close();
        });
    },
    getOptionShipmentNumber(
      type = 0,
      search = "",
      isGetDeliveryNumbers = true
    ) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/proof-of-delivery/filter", {
          params: {
            search,
            filter_type: 0,
            type,
            territory_ids:
              this.$store.getters["operatingUnit/getSelectedTerritoryId"],
            warehouse_ids:
              this.filter_warehouse === null
                ? undefined
                : [this.filter_warehouse.warehouse_id],
            start_delivery_date: this.depositDate.startDate
              ? this.dateFormat(this.depositDate.startDate)
              : "",
            end_delivery_date: this.depositDate.endDate
              ? this.dateFormat(this.depositDate.endDate)
              : "",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.optionShipmentNumber = [
              this.optionAllShipmentNumber,
              ...resp.data,
            ];
            this.$vs.loading.close();
            if (isGetDeliveryNumbers) {
              this.getOptionDeliveryOrder(type);
            }
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: err.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          this.$vs.loading.close();
        });
    },
    getOptionDeliveryOrder(type = 0, search = "") {
      this.$vs.loading();

      let shipment_numbers = null;
      if (
        this.selectedShipmentNumbers.length === 1 &&
        this.selectedShipmentNumbers[0].shipment_number === "All"
      ) {
        const temp = [...this.optionShipmentNumber];
        temp.shift();
        shipment_numbers = temp.map((item) => item.shipment_number);
      } else {
        shipment_numbers = this.selectedShipmentNumbers.map(
          (item) => item.shipment_number
        );
      }

      this.$http
        .get("/api/v1/proof-of-delivery/filter", {
          params: {
            search,
            shipment_numbers,
            filter_type: 1,
            type,
            territory_ids:
              this.$store.getters["operatingUnit/getSelectedTerritoryId"],
            warehouse_ids:
              this.filter_warehouse === null
                ? undefined
                : [this.filter_warehouse.warehouse_id],
            start_delivery_date: this.depositDate.startDate
              ? this.dateFormat(this.depositDate.startDate)
              : "",
            end_delivery_date: this.depositDate.endDate
              ? this.dateFormat(this.depositDate.endDate)
              : "",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.optionDeliveryOrder = [
              this.optionAllDeliveryOrder,
              ...resp.data,
            ];
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: err.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          this.$vs.loading.close();
        });
    },
    getOptionTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.territory.options = resp.data.records;
            console.log(this.territory.selected);
            if (this.territory.options.length > 0) {
              this.territory.selected = this.territory.options[0];
            }
            this.$vs.loading.close();
            this.getOptionWarehouse();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    onShipmentFilterChange(value) {
      if (value.length === 0) {
        this.getOptionShipmentNumber(this.currentTab, value, false);
        return;
      }
      if (value.length < 3) {
        return;
      }
      this.getOptionShipmentNumber(this.currentTab, value, false);
    },
    onDeliveryOrderChange(value) {
      if (value.length === 0) {
        this.getOptionDeliveryOrder(this.currentTab, value);
      }
      if (value.length < 3) {
        return;
      }
      this.getOptionDeliveryOrder(this.currentTab, value);
      // this.getOptionDeliveryOrder(value);
    },
    queryShipmentNumber({ shipment_number }) {
      return shipment_number;
    },
    queryDeliveryNumber({ do_number }) {
      return do_number;
    },
  },
};
</script>
<style>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>
