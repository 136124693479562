var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"POD Processing"}},[_c('div',{staticClass:"mb-6"},[_c('territory-filter')],1),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Warehouse")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full whitespace-no-wrap"},[_c('div',{staticClass:"mt-4 flex justify-between"},[_c('multiselect',{staticClass:"selectExample",attrs:{"options":!_vm.optionFilterWarehouse || _vm.optionFilterWarehouse.length == 0
              ? []
              : _vm.optionFilterWarehouse,"multiple":false,"allow-empty":true,"group-select":false,"max-height":160,"limit":4,"placeholder":"Type to search","track-by":"id","label":"warehouse_name","disabled":false},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(_vm._s(props.option.warehouse_code)+" - "+_vm._s(props.option.warehouse_name))])])]}}]),model:{value:(_vm.filter_warehouse),callback:function ($$v) {_vm.filter_warehouse=$$v},expression:"filter_warehouse"}}),_c('vs-button',{staticClass:"ml-4",attrs:{"color":"danger","icon-pack":"feather","icon":"icon-x"},on:{"click":() => {
              this.filter_warehouse = null;
              this.filterWarehouseID = 0;
            }}})],1)])]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Delivery Date")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full whitespace-no-wrap"},[_c('div',{staticClass:"mt-4 flex justify-between"},[_c('date-range-picker',{ref:"picker",staticClass:"w-full",staticStyle:{"min-height":"40px"},attrs:{"opens":"center","locale-data":{ firstDay: 1, format: 'dd-mm-yyyy' },"singleDatePicker":false,"timePicker":false,"timePicker24Hour":false,"showWeekNumbers":false,"showDropdowns":false,"autoApply":true,"linkedCalendars":true,"name":_vm.depositDate},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_vm._v(" "+_vm._s(_vm.globalDateFormat(picker.startDate))+" - "+_vm._s(_vm.globalDateFormat(picker.endDate))+" ")]}}]),model:{value:(_vm.depositDate),callback:function ($$v) {_vm.depositDate=$$v},expression:"depositDate"}}),_c('vs-button',{staticClass:"ml-4",attrs:{"color":"danger","icon-pack":"feather","icon":"icon-x"},on:{"click":() => {
              this.depositDate = { startDate: null, endDate: null };
            }}})],1)])]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Shipment Number")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full whitespace-no-wrap"},[_c('div',{staticClass:"mt-4 flex justify-between"},[_c('multiselect',{attrs:{"options":!_vm.optionShipmentNumber || _vm.optionShipmentNumber.length == 0
              ? []
              : _vm.optionShipmentNumber,"multiple":true,"allow-empty":true,"group-select":false,"max-height":160,"limit":2,"placeholder":"Type shipment number","track-by":"transport_plan_id","label":"shipment_number","custom-label":_vm.queryShipmentNumber},on:{"search-change":_vm.onShipmentFilterChange},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(" "+_vm._s(props.option.shipment_number))])])]}},{key:"tag",fn:function(props){return [_c('div',{staticClass:"multiselect__tag"},[_c('span',[_vm._v(_vm._s(props.option.shipment_number))]),_c('i',{staticClass:"multiselect__tag-icon",on:{"click":function($event){$event.preventDefault();},"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return props.remove(props.option, $event)}}})])]}}]),model:{value:(_vm.selectedShipmentNumbers),callback:function ($$v) {_vm.selectedShipmentNumbers=$$v},expression:"selectedShipmentNumbers"}})],1)])]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Delivery Order")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full whitespace-no-wrap"},[_c('div',{staticClass:"mt-4 flex justify-between"},[_c('multiselect',{attrs:{"options":!_vm.optionDeliveryOrder || _vm.optionDeliveryOrder.length == 0
              ? []
              : _vm.optionDeliveryOrder,"multiple":true,"allow-empty":true,"group-select":false,"max-height":160,"limit":3,"placeholder":"Type delivery number","track-by":"do_id","label":"shipment_number","custom-label":_vm.queryDeliveryNumber},on:{"search-change":_vm.onDeliveryOrderChange},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(" "+_vm._s(props.option.do_number))])])]}},{key:"tag",fn:function(props){return [_c('div',{staticClass:"multiselect__tag"},[_c('span',[_vm._v(_vm._s(props.option.do_number))]),_c('i',{staticClass:"multiselect__tag-icon",on:{"click":function($event){$event.preventDefault();},"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return props.remove(props.option, $event)}}})])]}}]),model:{value:(_vm.selectedDeliveryOrders),callback:function ($$v) {_vm.selectedDeliveryOrders=$$v},expression:"selectedDeliveryOrders"}})],1)])]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-7/12 w-full"}),_c('div',{staticClass:"vx-col sm:w-5/12 w-full"},[_c('vs-button',{staticClass:"mb-2",on:{"click":_vm.getData}},[_vm._v("Get Data")])],1)]),_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.detail ? _vm.detailShow + 'md:w-1/4 w-full mb-base' : '',
        _vm.detailHide,
      ]},[_c('vs-tabs',{attrs:{"color":_vm.colorx},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('vs-tab',{attrs:{"label":"Completed"},on:{"click":function($event){_vm.colorx = 'danger'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('apply',{attrs:{"depositDate":_vm.depositDate,"warehouseID":_vm.filter_warehouse ? _vm.filter_warehouse.warehouse_id : 0,"shipmentNumbers":_vm.selectedShipmentNumbers,"deliveryOrderNumbers":_vm.selectedDeliveryOrders,"optionDeliveryOrderNumber":_vm.optionDeliveryOrder,"optionShipmentNumber":_vm.optionShipmentNumber,"filterSearch":_vm.buttonSearch}})],1)]),_c('vs-tab',{attrs:{"label":"Processed"},on:{"click":function($event){_vm.colorx = 'warning'}}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('open',{attrs:{"depositDate":_vm.depositDate,"warehouseID":_vm.filter_warehouse ? _vm.filter_warehouse.warehouse_id : 0,"shipmentNumbers":_vm.selectedShipmentNumbers,"deliveryOrderNumbers":_vm.selectedDeliveryOrders,"optionDeliveryOrderNumber":_vm.optionDeliveryOrder,"optionShipmentNumber":_vm.optionShipmentNumber,"filterSearch":_vm.buttonSearch}})],1)])],1)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
          _vm.detail ? _vm.detailShow + 'md:w-3/4 w-full mb-base' : '',
          _vm.detailHide,
        ]},[_c('vs-row',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{staticClass:"ml-auto",attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}},[_vm._v("Close")])],1),_c('vs-col',{staticClass:"vertical-divider",attrs:{"vs-offset":"0","vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"1"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}})],1),_c('create',{on:{"close":_vm.handleClose}})],1)],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }